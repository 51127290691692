<template>
  <div>
    <title-bar :title="$t('navigations.administrators')"/>
    <div v-if="rows" class="custom-table">
      <table-actions
        :actions="tableActions"
        :addBtnText="$t('messages.administratorCreate')"
        :addBtnLink="$helper.getAddUrl('administrators')"
        :entries="pages"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:familyName="data">
          {{
            data.value.givenName + " " + data.value.familyName
          }}
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn
              v-if="canUpdate"
              :path="$helper.getEditUrl('administrators', data.value.id)"
            />
            <view-btn
              v-if="canView"
              :path="$helper.getViewUrl('administrators', data.value.id)"
            />
            <delete-btn
                v-if="canDelete"
                @pressDelete="deleteEmployee(data.value['@id'])"
            />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import {useToast} from "vue-toastification";
import Toast from "@/components/Toast.vue";
import ViewBtn from "@/components/Buttons/ViewBtn.vue";
import ListUtils from "@/mixins/ListUtils";

export default {
  name: "AdminHolidayListing",
  components: {
    ViewBtn,
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar,
  },
  mixins: [ResourceUtils, ListUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      enabled: true,
      checkedRows: [],
      showFilterModal: false,
      canView: false,
      canUpdate: false,
      canDelete: false,
      tableActions: [ 'search', 'perPage'],
      showColumnsModal: false,
      sortable: {
        order: "familyName",
        sort: "asc",
      },
      columns: [
        {
          label: this.$t("forms.name"),
          field: "familyName",
          sortable: true,
          enabled: true
        },
        {
          label: this.$t("forms.email"),
          field: "email",
          sortable: false,
          enabled: true
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
          enabled: true
        },
      ],
    };
  },
  created() {
    if (!this.$helper.isRoleAdmin()) {
        this.$router.push(`/`);
    }
    this.setPermissions();
    this.load();
  },
  methods: {
    load() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        role: "ROLE_ADMIN",
      };
      params["order[" + this.sortable.order + "]"] = this.sortable.sort;
      if (this.search && this.search.length) {
        params.search = this.search;
        delete params.status
      }
      this.$Users.getCollection({params}, 'administrator_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    setPermissions() {
      if (this.$helper.isRoleAdmin()) {
        this.tableActions.push('addBtn')
        this.canView = true
        this.canUpdate = true
        this.canDelete = true
      }
    },
    onChangePage(page) {
      this.currentPage = page;
      this.load();
    },
    deleteEmployee(url) {
      this.deleteByUrl(
        this.$Users,
        url,
        this.$t("messages.administratorDeleted"),
        null,
        this.load,
        this.error
      );
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.load();
    },
    onPageChange() {
      this.load();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.load();
    },
    filterSelectChange(enabled) {
      this.enabled = enabled.id
      if (enabled.id === "all") {
        this.enabled = [true, false]
      }
      this.load()
    },
    error() {
      this.$emit("clearAction");
    },
    updateCheckedRows(data) {
      this.checkedRows = [];
      data.forEach((value) => {
        this.checkedRows.push({
          administratorId: value,
          administrator: this.rows.find(row => parseInt(row.id) === parseInt(value)),
          index: this.rows.findIndex(row => parseInt(row.id) === parseInt(value)),
        })
      })
    },
    bulkPrintSubmitted() {
      if (this.checkedRows.length === 0) {
        this.$helper.showToast(
            useToast(),
            Toast,
            this.$t('messages.noPdfSelected'),
            this.$t('messages.selectAtLeastOnePdf'),
            "danger"
        );
      }

      if (this.checkedRows.length > 0) {

        const body = {
          resources: this.checkedRows.map((row) => row.administrator['@id']),
          name: 'bulk_administrator_card',
          templateName: 'BULK_EMPLOYEE_CARD',
        }

        this.$Pdfs
            .createResourceByUrl({ url: `/pdfs/bulk_print`, body } )
            .then((response) => {
              if (response.status === 200) {
                this.printPdf(response.data)
              }
            });
      }
    },
    printPdf(pdf) {
      const link = document.createElement("a");
      link.href = pdf.notCachedUrl;
      link.target = "_blank";
      link.click();
    },
  },
};
</script>

<style lang="scss">
</style>
